import React from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

function ParameterInputRender({ apiPath, control, defaultValue }) {
  const apiWordGroup = _.split(apiPath, '{');
  const variableList = [];
  //  console.log(_.words(apiPath, /[^{ ]+/g));
  _.map(apiWordGroup, (eachWord) => {
    if (_.includes(eachWord, '}')) {
      const variableNameSet = _.words(eachWord, /[^} ]+/g);
      const variableName = _.first(variableNameSet);
      variableList.push(variableName);
    }
  });

  // console.log('Variable List', variableList);

  return (
    <div className="flex flex-col">
      {_.map(variableList, (eachVariable, index) => (
        <div key={index} className="my-1">
          <Controller
            control={control}
            name={`parameters.${eachVariable}`}
            defaultValue={defaultValue?.parameters?.[eachVariable]}
            render={({ field }) => (
              <TextField size="small" label={eachVariable} {...field} />
            )}
          />
        </div>
      ))}
    </div>
  );
}

export default ParameterInputRender;

ParameterInputRender.propTypes = {
  apiPath: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.object,
};
