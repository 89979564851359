import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';

import { EAccom, EMaintenance, EManufac, EMerchant } from '../views/APIDoc';
import { HomeNavbar } from '../components/Nevbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { NotFound } from '../components/Error';
// import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function APIDoc() {
  const module = 'API_DOC';
  const prefix = '/apidoc';
  const name = 'API Document';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />{' '}
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="relative   lg:ml-64  min-h-screen  pt-10 px-1">
        <div className="">
          <Switch>
            <Route exact path={`${prefix}/`}>
              <div></div>
            </Route>
            <Route path={`${prefix}/eaccom`}>
              <EAccom title="E-Accom" subtitle={name} />
            </Route>

            <Route path={`${prefix}/emerchant`}>
              <EMerchant title="E-Merchant" subtitle={name} />
            </Route>
            <Route path={`${prefix}/emanufac`}>
              <EManufac title="E-Manufac" subtitle={name} />
            </Route>
            <Route path={`${prefix}/emaintenance`}>
              <EMaintenance title="E-Maintenance" subtitle={name} />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default APIDoc;
