import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

const DestinationSystemTable = ({
  setPage,
  setSize,
  page,
  size,
  total,
  handleDelete,
  data,
}) => {
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อระบบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(data?.rows) ? (
                data?.rows?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{row?.name}</TableCell>

                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Link
                          to={`destination-system/prediction-source/${row?._id}`}
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            size={'small'}
                          >
                            ตัวแปรที่ใช้ทำนาย
                          </Button>
                        </Link>
                        <Link to={`destination-system/edit/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'warning'}
                            size={'small'}
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDelete(row?._id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

export default DestinationSystemTable;

DestinationSystemTable.propTypes = {
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleDelete: PropTypes.func,
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
