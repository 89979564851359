import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import ProjectAttributeRowDisplayMode from './ProjectAttributeRowDisplayMode';
import ProjectAttributeRowEditorMode from './ProjectAttributeRowEditorMode';

function ProjectAttributeRow({
  dataIndex,
  dataRow,
  handleDelete,
  handleUpdate,
}) {
  const [isEditorMode, setEditorMode] = useState(false);
  const { control, handleSubmit, watch } = useForm({});

  if (isEditorMode) {
    return (
      <ProjectAttributeRowEditorMode
        dataIndex={dataIndex}
        dataRow={dataRow}
        control={control}
        handleDisplayMode={() => setEditorMode(false)}
        handleUpdateDatabase={(e) => {
          setEditorMode(false);
          handleSubmit(handleUpdate)(e);
        }}
        watch={watch}
      />
    );
  }

  return (
    <ProjectAttributeRowDisplayMode
      dataIndex={dataIndex}
      dataRow={dataRow}
      handleDelete={handleDelete}
      handleEditorMode={() => setEditorMode(true)}
    />
  );
}

export default ProjectAttributeRow;

ProjectAttributeRow.propTypes = {
  dataIndex: PropTypes.number,
  dataRow: PropTypes.object,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
};
