import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, Link } from 'react-router-dom';
import { ViewTitle } from '../components';
import { HomeNavbar } from '../components/Nevbars/index';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';

export default function Home() {
  // const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="min-h-screen">
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        // me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

      <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <ViewTitle title="API Document Portal" subtitle="IARC Dev Hub" />
          <div className="grid md:grid-cols-4">
            <Link className="px-2" to={'/apidoc/eaccom'}>
              <div className="bg-white shadow-md p-4 py-10 text-2xl text-center hover:shadow-xl cursor-pointer">
                E-Accom
              </div>
            </Link>
            <Link className="px-2" to={'/apidoc/emerchant'}>
              <div className="bg-white shadow-md p-4 py-10 text-2xl text-center hover:shadow-xl cursor-pointer">
                E-Merchant
              </div>
            </Link>
            <Link className="px-2" to={'/apidoc/emanufac'}>
              <div className="bg-white shadow-md p-4 py-10 text-2xl text-center hover:shadow-xl cursor-pointer">
                E-Manufac
              </div>
            </Link>
            <Link className="px-2" to={'/apidoc/emachine'}>
              <div className="bg-white shadow-md p-4 py-10 text-2xl text-center hover:shadow-xl cursor-pointer">
                E-Machine
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}
