import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Autocomplete,
  TextField,
} from '@mui/material';
import React from 'react';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import PredictionSourceForm from './PredictionSourceForm';

function PredictionAttributeForm({
  control,
  destinationSystem,
  watch,
  predictionSource,
  errors,
}) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2 px-1">
        <div className="font-semibold">ที่มาของตัวแปร</div>
        <Controller
          control={control}
          name="createNew"
          render={({ field }) => (
            <FormControl className="flex gap-2">
              <FormControlLabel
                label="สร้างใหม่"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            </FormControl>
          )}
        />
      </div>
      {watch('createNew') === true ? (
        <div className="w-full py-2 px-1">
          <PredictionSourceForm
            control={control}
            destinationSystem={destinationSystem}
            errors={errors}
          />
        </div>
      ) : (
        <div className="w-full py-2 px-1">
          {!_.isEmpty(predictionSource?.rows) && (
            <Controller
              name="prediction_source"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={predictionSource?.rows}
                  size="small"
                  placeholder="ระบบต้นแบบ"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
          }
                  renderInput={(params) => (
                    <TextField label="ตัวแปรที่ต้องการทำนาย" {...params} />
                  )}
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PredictionAttributeForm;

PredictionAttributeForm.propTypes = {
  control: PropTypes.object,
  destinationSystem: PropTypes.object,
  watch: PropTypes.func,
  predictionSource: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
};
