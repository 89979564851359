import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import {
  Loading,
  Error,
  ViewTitle,
  BackButton,
  PredictionAttributeForm,
} from '../../components';

function CreateProjectPredictionAttribute({ title, subtitle }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const predictionSource = useSelector((state) => state.predictionSource);
  const params = useParams();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const getProjects = async () => {
    dispatch(actions.projectGet(params.id));
  };

  const getPredictionSource = async () => {
    try {
      await dispatch(
        actions.predictionSourceAll({
          destinationSystem: project?.base_system?._id,
          page: 1,
          size: 300,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลตัวแปรที่ใช้ทำนายได้ ${error?.message}`);
    }
  };

  useEffect(() => {
    getProjects();
    return () => {};
  }, [params]);

  useEffect(() => {
    getPredictionSource();
    return () => {};
  }, []);

  const handleCreatePredictionAttribute = async (data) => {
    try {
      await dispatch(
        actions.projPredictAttrCreate({
          ...data,
          project: params.id,
          baseSystem: project?.base_system?._id,
        }),
      );
      history.goBack();
    } catch (error) {
      alert(`สร้างตัวแปรไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => (
    <ViewTitle
      title={`${title}ของโปรเจกต์ ${project?.name}`}
      subtitle={subtitle}
    />
  );

  const renderBack = () => (
    <div className="my-2">
      <BackButton />
    </div>
  );

  if (project.isLoading) {
    return <Loading />;
  }
  if (!project.isLoading && project.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderBack()}
        <div>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleCreatePredictionAttribute)}>
                <PredictionAttributeForm
                  control={control}
                  destinationSystem={project?.base_system}
                  errors={errors}
                  predictionSource={predictionSource}
                  watch={watch}
                />
                <div className="flex justify-end">
                  <Button type="submit" variant="contained">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateProjectPredictionAttribute.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProjectPredictionAttribute.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateProjectPredictionAttribute;
