import React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { OPTIMIZATION_ALGORITHM, SAMPLING_RATE } from '../../utils/constants';

const ProjectAttributeRowDisplayMode = ({
  handleDelete,
  dataRow,
  dataIndex,
  handleEditorMode,
}) => {
  return (
    <TableRow
      key={dataRow._id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {dataIndex}
      </TableCell>
      <TableCell>
        <div className="font-semibold">{dataRow?.prediction_source?.name}</div>
        <div className="w-32">
          API Path: <code>{dataRow?.prediction_source?.api_suffix} </code>
        </div>
      </TableCell>

      <TableCell>
        <div>{dayjs(dataRow?.start).format('D MMM YYYY HH:mm')} - </div>
        <div>{dayjs(dataRow?.end).format('D MMM YYYY HH:mm')}</div>
      </TableCell>
      <TableCell>
        <div>
          <b>Sampling Rate: </b>{' '}
          {SAMPLING_RATE[dataRow?.sampling_rate]?.description || '-'}
        </div>

        <div>
          <b>Learning Rate:</b> {dataRow?.learning_rate}
        </div>
      </TableCell>
      <TableCell>
        {_.map(dataRow?.parameters, (value, index) => (
          <div>
            <span className="font-semibold">{index}: </span> {value}
          </div>
        ))}
      </TableCell>
      <TableCell>
        <div className="flex flex-row flex-wrap gap-1 my-1">
          <Link to={`/project/project/api-test/${dataRow?._id}`}>
            <Button variant="contained" color="info" size={'small'}>
              ทดสอบ API
            </Button>
          </Link>
          <Link
            to={`/project/project/prediction-attribute/train/${dataRow?._id}`}
          >
            <Button variant="contained" color="secondary" size={'small'}>
              Train
            </Button>
          </Link>
          <Link
            to={`/project/project/prediction-attribute/settime/${dataRow?._id}`}
          >
            <Button variant="contained" color="primary" size={'small'}>
              ตั้งเวลา
            </Button>
          </Link>
        </div>
        <div className="flex flex-row flex-wrap gap-1 ">
          <Button
            variant="contained"
            color={'warning'}
            size={'small'}
            onClick={handleEditorMode}
          >
            แก้ไข
          </Button>

          <Button
            variant="contained"
            color={'error'}
            size={'small'}
            onClick={() => {
              handleDelete(dataRow?._id);
            }}
          >
            ลบ
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

ProjectAttributeRowDisplayMode.propTypes = {
  handleDelete: PropTypes.func,
  dataRow: PropTypes.object,
  dataIndex: PropTypes.number,
  handleEditorMode: PropTypes.func,
};

export default ProjectAttributeRowDisplayMode;
