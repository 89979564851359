export const SAMPLING_RATE = {
  ONE_MINUTE: {
    status_code: 'ONE_MINUTE',
    description: '1 Min',
  },
  THREE_MINUTE: {
    status_code: 'THREE_MINUTE',
    description: '3 Min',
  },
  FIVE_MINUTE: {
    status_code: 'FIVE_MINUTE',
    description: '5 Min',
  },
  ONE_HOUR: {
    status_code: 'ONE_HOUR',
    description: '1 Hrs',
  },
  FIVE_HOUR: {
    status_code: 'FIVE_HOUR',
    description: '5 Hrs',
  },
  ONE_DAY: {
    status_code: 'ONE_DAY',
    description: '1 Days',
  },
};

export default SAMPLING_RATE;
