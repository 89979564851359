import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  BackButton,
  ViewTitle,
  ProjectForm,
  Loading,
  Error,
} from '../../components';
import * as actions from '../../redux/actions';
import { config } from '../../utils/constants';

function EditProject({ title, subtitle }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const destinationSystem = useSelector((state) => state.destinationSystem);
  const project = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(
      actions.destinationSystemAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(actions.projectGet(params.id));
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.projectPut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สำเร็จ ${error?.message || ''}`);
    }
  };

  if (project?.isLoading) {
    return <Loading />;
  }

  if (!project?.rows && project?.isCompleted && !project?.isLoading) {
    return (
      <div>
        <div>
          <div className="flex justify-between">
            <ViewTitle title={title} subtitle={subtitle} />
          </div>
          <BackButton />
        </div>
        <div className="my-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <ProjectForm
                  control={control}
                  errors={errors}
                  destinationSystem={destinationSystem}
                  showAllowCreateCheck
                  project={project}
                />
              </CardContent>
            </Card>
            <div className="flex justify-end my-2">
              <Button color="primary" variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return <Error />;
}

export default EditProject;

EditProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProject.defaultProps = {
  title: '',
  subtitle: '',
};
