import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import {
  Loading,
  Error,
  ViewTitle,
  PredictionSourceTable,
  PredictionSourceForm,
} from '../../components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function SystemPredictionSource({ title, subtitle }) {
  const dispatch = useDispatch();
  const predictionSource = useSelector((state) => state.predictionSource);
  const destinationSystem = useSelector((state) => state.destinationSystem);
  const params = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getPredictionSource = async () => {
    try {
      await dispatch(
        actions.predictionSourceAll({
          name,
          page,
          size,
          destinationSystem: params.id,
        }),
      );
    } catch (error) {
      alert(`Get Prediction Sources Error ${error?.message}`);
    }
  };

  const getDestinationSystem = async () => {
    try {
      await dispatch(actions.destinationSystemGet(params.id));
    } catch (error) {
      alert(`Get Destination System Error ${error?.message}`);
    }
  };

  useEffect(() => {
    getPredictionSource();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    getDestinationSystem();
    return () => {};
  }, [params]);

  useEffect(() => {
    setTotal(predictionSource?.total);
    return () => {};
  }, [predictionSource]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${destinationSystem?.name}`}
      subtitle={subtitle}
    />
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.predictionSourceDelete(id));
        getPredictionSource();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onCreatePredictionSource = async (data) => {
    try {
      const payload = { ...data, system: params.id };
      await dispatch(actions.predictionSourceCreate(payload));
      getPredictionSource();
      reset();
      setIsModalOpen(false);
    } catch (error) {
      alert(`สร้างตัวแปรทำนายไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Button
        variant="contained"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        เพิ่ม
      </Button>
    </div>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มตัวแปรที่ใช้ทำนาย</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onCreatePredictionSource)}>
              <PredictionSourceForm control={control} errors={errors} />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <PredictionSourceTable
        data={predictionSource}
        handleDelete={handleDelete}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
      />
    </div>
  );

  if (predictionSource.isLoading) {
    return <Loading />;
  }
  if (!predictionSource.isLoading && predictionSource.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderModal()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

SystemPredictionSource.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SystemPredictionSource.defaultProps = {
  title: '',
  subtitle: '',
};

export default SystemPredictionSource;
