import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ReactJson from 'react-json-view';

import { BackButton, ViewTitle, ProjectForm } from '../../components';
import * as actions from '../../redux/actions';
import { config } from '../../utils/constants';
import { decodeAPIURL, api } from '../../utils/functions';

function APITest({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const projPredictAttr = useSelector((state) => state.projPredictAttr);
  const [fullAPIURL, setFullAPIURL] = useState('');
  const [apiResponse, setAPIResponse] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: projPredictAttr,
  });
  useEffect(() => {
    dispatch(actions.projPredictAttrGet(params.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (projPredictAttr && !projPredictAttr?.rows) {
      const fullAPI = decodeAPIURL(
        projPredictAttr?.project?.api_prefix,
        projPredictAttr?.prediction_source?.api_suffix,
        projPredictAttr?.parameters,
      );
      setFullAPIURL(fullAPI);
    }

    return () => {};
  }, [projPredictAttr]);

  const handleTest = async () => {
    try {
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/project-prediction-attribute/test/`,
        {
          url: fullAPIURL,
          token: projPredictAttr?.process?.access_token,
        },
      );
      setAPIResponse(data);
    } catch (error) {
      window.alert(`ไม่สามารถดึงค่าจาก API ได้ ${error?.message}`);
    }
  };

  const handleEditField = async (data) => {
    try {
      await dispatch(actions.projPredictAttrPut(params.id, data));
      dispatch(actions.projPredictAttrGet(params.id));
      setIsModalOpen(false);
      reset({});
    } catch (error) {
      alert(`แก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderModal = () => (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>แก้ไขฟิลล์อ้างอิง</DialogTitle>
      <form onSubmit={handleSubmit(handleEditField)}>
        <DialogContent>
          <div className="flex flex-wrap">
            <div className="w-full my-2">
              <Controller
                control={control}
                name="main_ref_column"
                defaultValue={projPredictAttr?.main_ref_column}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    label="ฟิลล์อ้างอิงข้อมูล"
                  />
                )}
              />
            </div>
            <div className="w-full my-2">
              <Controller
                control={control}
                name="date_ref_column"
                defaultValue={projPredictAttr?.date_ref_column}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    label="ฟิลล์อ้างอิงเวลา"
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="success" type="submit" variant="contained">
            บันทึก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <ViewTitle title={title} subtitle={subtitle} />
        </div>
        {renderModal()}
        <div className="flex justify-between">
          <BackButton />
          <div className="flex gap-2">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleTest()}
            >
              ทดสอบ
            </Button>{' '}
            <Button
              color="warning"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
            >
              แก้ไขฟิลล์อ้างอิง
            </Button>
          </div>
        </div>
      </div>
      <div className="my-4">
        <Card>
          <CardContent>
            <h3 className="text-lg font-bold my-2">ข้อมูลตัวแปร</h3>
            <div className="flex flex-wrap">
              <div className="w-full my-1 lg:w-1/4 font-semibold">
                ชื่อตัวแปร
              </div>
              <div className="w-full my-1 lg:w-3/4">
                {projPredictAttr?.prediction_source?.name}
              </div>
              <div className="w-full my-1 lg:w-1/4 font-semibold">
                ชื่อโปรเจกต์
              </div>
              <div className="w-full my-1 lg:w-3/4">
                {projPredictAttr?.project?.name}
              </div>

              <div className="w-full my-1 lg:w-1/4 font-semibold">
                API Endpoint
              </div>
              <div className="w-full my-1 lg:w-3/4">
                {projPredictAttr?.project?.api_prefix}
                {projPredictAttr?.prediction_source?.api_suffix}
              </div>

              <div className="w-full my-1 lg:w-1/4 font-semibold">
                Full Request Endpoint
              </div>
              <div className="w-full my-1 lg:w-3/4">{fullAPIURL}</div>
              <div className="w-full my-1 lg:w-1/4 font-semibold">
                ฟิลล์อ้างอิงข้อมูล
              </div>
              <div className="w-full my-1 lg:w-1/4">
                {projPredictAttr?.main_ref_column}
              </div>
              <div className="w-full my-1 lg:w-1/4 font-semibold">
                ฟิลล์อ้างอิงเวลา
              </div>
              <div className="w-full my-1 lg:w-1/4">
                {projPredictAttr?.date_ref_column}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        <Card>
          <CardContent>
            <code>
              <ReactJson src={apiResponse} />
            </code>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default APITest;

APITest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

APITest.defaultProps = {
  title: '',
  subtitle: '',
};
