import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import * as actions from '../../redux/actions';
import {
  Loading,
  Error,
  ViewTitle,
  ProjectAttributeTable,
  BackButton,
} from '../../components';

function EachProjectAttributes({ title, subtitle }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const projPredictAttr = useSelector((state) => state.projPredictAttr);
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(undefined);

  const getProjects = async () => {
    dispatch(actions.projectGet(params.id));
  };

  const getPredictionAttributes = async () => {
    try {
      await dispatch(
        actions.projPredictAttrAll({
          name: '',
          page,
          size,
          project: params.id,
        }),
      );
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลตัวแปรที่ใช้ทำนายได้ ${error?.message}`);
    }
  };

  const handleUpdate = async (data) => {
    try {
      console.log('Data', data);
      await dispatch(actions.projPredictAttrPut(data?.id, data));
      getPredictionAttributes();
    } catch (error) {
      alert(`ไม่สามารถอัพเดทข้อมูลตัวแปรที่ใช้ทำนายได้ ${error?.message}`);
    }
  };

  useEffect(() => {
    getProjects();
    return () => {};
  }, [params]);

  useEffect(() => {
    getPredictionAttributes();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(project?.total);
    return () => {};
  }, [project]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title}ของโปรเจกต์ ${project?.name}`}
      subtitle={subtitle}
    />
  );

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.projectDelete(id));
        getProjects();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Link to={`create/${params.id}`}>
        <Button variant="contained">เพิ่ม</Button>
      </Link>
      <Button variant="contained" color="secondary">
        อัพเดทจากระบบต้นแบบ
      </Button>

      <Link to={`/project/project/edit/${params.id}`}>
        <Button variant="contained" color="warning">
          แก้ไขโปรเจกต์
        </Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderBack = () => (
    <div className="my-2">
      <BackButton />
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <ProjectAttributeTable
        data={projPredictAttr}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        page={page}
        setPage={setPage}
        setSize={setSize}
        size={size}
        total={total}
      />
    </div>
  );

  if (project.isLoading) {
    return <Loading />;
  }
  if (!project.isLoading && project.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderBack()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

EachProjectAttributes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EachProjectAttributes.defaultProps = {
  title: '',
  subtitle: '',
};

export default EachProjectAttributes;
