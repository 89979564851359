import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';

import { api } from '../../utils/functions';
import { BackButton, ViewTitle } from '../../components';

function HealthCheck({ title, subtitle }) {
  const [engineStatus, setEngineStatus] = useState({});
  const getHealth = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/engine/health`,
      );
      console.log('Data', data);
      setEngineStatus(data);
    } catch (error) {
      alert(`ไม่สามารถเช็คสถานะได้ ${error?.message}`);
    }
  };

  useEffect(() => {
    getHealth();
    return () => {};
  }, []);

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <ViewTitle title={title} subtitle={subtitle} />
        </div>
        <BackButton />
      </div>
      <div className="my-4">
        {!_.isEmpty(engineStatus) ? (
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="my-1 w-full font-semibold text-lg">
                  สถานะ Apache Airflow Engine
                </div>
                <hr className="w-full my-2" />
                <div className="w-full my-2 lg:w-1/4 font-semibold">
                  ตำแหน่งของ Airflow Engine
                </div>
                <div className="w-full my-2 lg:w-3/4">
                  {engineStatus?.airflowURL}
                </div>

                <div className="w-full my-2 lg:w-1/4 font-semibold">
                  สถานะการเชื่อมต่อฐานข้อมูล
                  <div className="text-sm">(Meta Database)</div>
                </div>
                <div className="w-full my-2 lg:w-3/4">
                  {engineStatus?.metadatabase?.status}
                </div>

                <div className="w-full my-2 lg:w-1/4 font-semibold">
                  สถานะการจัดตารางงาน
                  <div className="text-sm">(Scheduler)</div>
                </div>
                <div className="w-full my-2 lg:w-3/4">
                  {engineStatus?.scheduler?.status}
                </div>
                <div className="w-full my-2 lg:w-1/4 font-semibold">
                  เวลาที่ Sync ครั้งล่าสุด
                  <div className="text-sm">(Latest Scheduler Heartbeat)</div>
                </div>
                <div className="w-full my-2 lg:w-3/4">
                  {dayjs(
                    engineStatus?.scheduler?.latest_scheduler_heartbeat,
                  ).format('D MMM YYYY HH:mm')}
                </div>
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              ไม่สามารถเช็คสถานะได้ อาจจะเกิดจากการ Engine เกิดความผิดพลาด
              หรือปิดทำการ
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}

export default HealthCheck;

HealthCheck.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HealthCheck.defaultProps = {
  title: '',
  subtitle: '',
};
