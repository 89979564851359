import {
  DESTINATION_SYSTEM_ALL,
  DESTINATION_SYSTEM_GET,
  DESTINATION_SYSTEM_DEL,
  DESTINATION_SYSTEM_PUT,
  DESTINATION_SYSTEM_POST,
  DESTINATION_SYSTEM_LOADING,
  DESTINATION_SYSTEM_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const destinationSystemCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: DESTINATION_SYSTEM_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/destination-system`,
      payload,
    );
    dispatch({ type: DESTINATION_SYSTEM_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DESTINATION_SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const destinationSystemAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = null, page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/destination-system?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DESTINATION_SYSTEM_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DESTINATION_SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const destinationSystemGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/destination-system/${id}`,
    );
    if (status === 200) {
      dispatch({ type: DESTINATION_SYSTEM_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DESTINATION_SYSTEM_ERROR });
    throw new Error(error);
  }
};

export const destinationSystemPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: DESTINATION_SYSTEM_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/destination-system/${id}`,
      payload,
    );
    dispatch({ type: DESTINATION_SYSTEM_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DESTINATION_SYSTEM_ERROR });
    throw new Error(error);
  }
};
export const destinationSystemDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DESTINATION_SYSTEM_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/destination-system/${id}`,
    );
    dispatch({ type: DESTINATION_SYSTEM_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: DESTINATION_SYSTEM_ERROR });
    throw new Error(error);
  }
};
