import { green, red, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    amber: {
      main: '#ffc107',
    },
    primary: {
      main: '#4682B4',
      light: '#74A1C7',
      dark: '#356287',
    },
    cyan: {
      main: '#1585C7',
    },
    secondary: {
      main: '#464BB4',
    },
    indigo: {
      main: '#7846B4',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: yellow[800],
    },
    success: {
      main: green[500],
    },
    teal: {
      main: green[500],
    },
    danger: {
      main: red[500],
    },
    lightGreen: {
      main: '#c8e6c9',
    },
    gray: {
      main: '#9e9e9e',
    },
    background: {
      default: '#F4F5F7',
    },
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans Thai',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    // fontSize: 12,
  },
});

export default theme;
