import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
} from '@mui/material';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ProjectAttributeRow from '../Box/ProjectAttributeRow';

const ProjectAttributeTable = ({
  setPage,
  setSize,
  page,
  size,
  total,
  handleDelete,
  handleUpdate = () => {},
  data,
}) => {
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อตัวแปร</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold"> เริ่มต้น-สิ้นสุด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> Parameters</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(data?.rows) ? (
                data?.rows?.map((row, index) => (
                  <ProjectAttributeRow
                    key={index}
                    dataIndex={(page - 1) * size + index + 1}
                    dataRow={row}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
};

export default ProjectAttributeTable;

ProjectAttributeTable.propTypes = {
  setPage: PropTypes.func,
  setSize: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
