import {
  PROJECT_ALL,
  PROJECT_GET,
  PROJECT_DEL,
  PROJECT_PUT,
  PROJECT_POST,
  PROJECT_LOADING,
  PROJECT_ERROR,
} from '../../actions/types';

const initialState = {
  employee: null,
  isLoading: true,
  isCompleted: true,
};
export default function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROJECT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROJECT_POST:
      return { isLoading: false, isCompleted: true };
    case PROJECT_PUT:
      return { isLoading: false, isCompleted: true };
    case PROJECT_DEL:
      return { isLoading: false, isCompleted: true };
    case PROJECT_LOADING:
      return { isLoading: true, isCompleted: true };
    case PROJECT_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
