import React from 'react';
import PropTypes from 'prop-types';
import { API } from '@stoplight/elements';
import { ViewTitle } from '../../components';
import '@stoplight/elements/styles.min.css';

function EAccom({ title, subtitle }) {
  return (
    <div>
      <div className="my-4">
        <API
          apiDescriptionUrl="/openapi/open-ea.yml"
          basePath="/apidoc/eaccom"
        />
      </div>
    </div>
  );
}

export default EAccom;

EAccom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EAccom.defaultProps = {
  title: '',
  subtitle: '',
};
