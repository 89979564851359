import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
// feature
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import PredictionSourceReducer from './features/PredictionSourceReducer';
import DestinationSystemReducer from './features/DestinationSystemReducer';
import ProjectReducer from './features/ProjectReducer';
import ProjectPredictionAttributeReducer from './features/ProjectPredictionAttributeReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  destinationSystem: DestinationSystemReducer,
  predictionSource: PredictionSourceReducer,
  project: ProjectReducer,
  projPredictAttr: ProjectPredictionAttributeReducer,
});
export default rootRuducer;
