import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as actions from '../../redux/actions';
import { Loading, Error, ViewTitle, ProjectTable } from '../../components';

function Projects({ title, subtitle }) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  const getProjects = async () => {
    dispatch(actions.projectAll({ name, page, size }));
  };

  useEffect(() => {
    getProjects();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(project?.total);
    return () => {};
  }, [project]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.projectDelete(id));
        getProjects();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="project/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <ProjectTable
        data={project}
        handleDelete={handleDelete}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
      />
    </div>
  );

  if (project.isLoading) {
    return <Loading />;
  }
  if (!project.isLoading && project.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Projects.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Projects.defaultProps = {
  title: '',
  subtitle: '',
};

export default Projects;
