import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BackButton, ViewTitle, ProjectForm } from '../../components';
import * as actions from '../../redux/actions';
import { config } from '../../utils/constants';

function CreateProject({ title, subtitle }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const destinationSystem = useSelector((state) => state.destinationSystem);

  useEffect(() => {
    dispatch(
      actions.destinationSystemAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    return () => {};
  }, []);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.projectCreate(data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สำเร็จ ${error?.message || ''}`);
    }
  };

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <ViewTitle title={title} subtitle={subtitle} />
        </div>
        <BackButton />
      </div>
      <div className="my-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <ProjectForm
                control={control}
                errors={errors}
                destinationSystem={destinationSystem}
                showAllowCreateCheck
              />
            </CardContent>
          </Card>
          <div className="flex justify-end my-2">
            <Button color="primary" variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProject;

CreateProject.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateProject.defaultProps = {
  title: '',
  subtitle: '',
};
