import React from 'react';
import PropTypes from 'prop-types';
import { API } from '@stoplight/elements';
import { ViewTitle } from '../../components';
import '@stoplight/elements/styles.min.css';

function EMaintenance({ title, subtitle }) {
  return (
    <div>
      <div className="my-4">
        <API
          apiDescriptionUrl="/openapi/open-emaintenance.yml"
          basePath="/apidoc/emaintenance"
        />
      </div>
    </div>
  );
}

export default EMaintenance;

EMaintenance.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EMaintenance.defaultProps = {
  title: '',
  subtitle: '',
};
