import {
  PREDICTION_SOURCE_ALL,
  PREDICTION_SOURCE_GET,
  PREDICTION_SOURCE_DEL,
  PREDICTION_SOURCE_PUT,
  PREDICTION_SOURCE_POST,
  PREDICTION_SOURCE_LOADING,
  PREDICTION_SOURCE_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const predictionSourceCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PREDICTION_SOURCE_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/prediction-source`,
      payload,
    );
    dispatch({ type: PREDICTION_SOURCE_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PREDICTION_SOURCE_ERROR });
    throw new Error(error);
  }
};

export const predictionSourceAll = ({
  name = '',
  size = null,
  page = 1,
  destinationSystem = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/prediction-source?name=${name}&size=${size}&page=${page}&destinationSystem=${destinationSystem}`,
    );
    if (status === 200) {
      dispatch({ type: PREDICTION_SOURCE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PREDICTION_SOURCE_ERROR });
    throw new Error(error);
  }
};

export const predictionSourceGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/prediction-source/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PREDICTION_SOURCE_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PREDICTION_SOURCE_ERROR });
    throw new Error(error);
  }
};

export const predictionSourcePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PREDICTION_SOURCE_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/prediction-source/${id}`,
      payload,
    );
    dispatch({ type: PREDICTION_SOURCE_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PREDICTION_SOURCE_ERROR });
    throw new Error(error);
  }
};
export const predictionSourceDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PREDICTION_SOURCE_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/prediction-source/${id}`,
    );
    dispatch({ type: PREDICTION_SOURCE_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PREDICTION_SOURCE_ERROR });
    throw new Error(error);
  }
};
