import React from 'react';
import PropTypes from 'prop-types';
import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';

function EMerchant({ title, subtitle }) {
  return (
    <div>
      <div className="my-4">
        <API
          apiDescriptionUrl="/openapi/open-emerchant.yml"
          basePath="/apidoc/emerchant"
        />
      </div>
    </div>
  );
}

export default EMerchant;

EMerchant.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EMerchant.defaultProps = {
  title: '',
  subtitle: '',
};
