import {
  DESTINATION_SYSTEM_ALL,
  DESTINATION_SYSTEM_GET,
  DESTINATION_SYSTEM_DEL,
  DESTINATION_SYSTEM_PUT,
  DESTINATION_SYSTEM_POST,
  DESTINATION_SYSTEM_LOADING,
  DESTINATION_SYSTEM_ERROR,
} from '../../actions/types';

const initialState = {
  employee: null,
  isLoading: true,
  isCompleted: true,
};
export default function DestinationSystemReducer(state = initialState, action) {
  switch (action.type) {
    case DESTINATION_SYSTEM_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DESTINATION_SYSTEM_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DESTINATION_SYSTEM_POST:
      return { isLoading: false, isCompleted: true };
    case DESTINATION_SYSTEM_PUT:
      return { isLoading: false, isCompleted: true };
    case DESTINATION_SYSTEM_DEL:
      return { isLoading: false, isCompleted: true };
    case DESTINATION_SYSTEM_LOADING:
      return { isLoading: true, isCompleted: true };
    case DESTINATION_SYSTEM_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
