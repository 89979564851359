import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export function PredictionSourceForm({
  errors,
  predictionSource,
  control,
  destinationSystem,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name="name"
          control={control}
          defaultValue={predictionSource ? predictionSource.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อตัวแปร"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(destinationSystem?.rows) && (
          <Controller
            name="system"
            control={control}
            defaultValue={predictionSource ? predictionSource.system : {}}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                options={destinationSystem?.rows}
                size="small"
                placeholder="เลือกระบบ"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                className="z-40"
                // prettier-ignore
                getOptionLabel={(option) => `${option?.name || ''}`
          }
                renderInput={(params) => (
                  <TextField label="เลือกระบบ" {...params} />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name="api_suffix"
          control={control}
          defaultValue={predictionSource ? predictionSource.api_suffix : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Path ของ API"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    https://example.com/api/v1/
                  </InputAdornment>
                ),
              }}
              size={'small'}
              helperText={'กรณีต้องการเก็บเป็นตัวแปรกรุณาใส่ {} ครอบ'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="main_ref_column"
          control={control}
          defaultValue={
            predictionSource ? predictionSource.main_ref_column : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคอลลัมม์ที่เก็บข้อมูล"
              fullWidth
              required
              size={'small'}
              helperText={errors.main_ref_column && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name="date_ref_column"
          control={control}
          defaultValue={
            predictionSource ? predictionSource.date_ref_column : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคอลลัมม์ที่เก็บวันที่"
              fullWidth
              size={'small'}
              helperText={errors.date_ref_column && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

PredictionSourceForm.propTypes = {
  errors: PropTypes.object,
  predictionSource: PropTypes.object,
  control: PropTypes.object,
  destinationSystem: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default PredictionSourceForm;
