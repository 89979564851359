import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export function DestinationSystemForm({ errors, destinationSystem, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-1">
        <Controller
          name="name"
          control={control}
          defaultValue={destinationSystem ? destinationSystem.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-1">
        <Controller
          name="description"
          control={control}
          defaultValue={destinationSystem ? destinationSystem.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

DestinationSystemForm.propTypes = {
  errors: PropTypes.object,
  destinationSystem: PropTypes.object,
  control: PropTypes.object,
};

export default DestinationSystemForm;
