import {
  PREDICTION_SOURCE_ALL,
  PREDICTION_SOURCE_GET,
  PREDICTION_SOURCE_DEL,
  PREDICTION_SOURCE_PUT,
  PREDICTION_SOURCE_POST,
  PREDICTION_SOURCE_LOADING,
  PREDICTION_SOURCE_ERROR,
} from '../../actions/types';

const initialState = {
  employee: null,
  isLoading: true,
  isCompleted: true,
};
export default function PredictionSourceReducer(state = initialState, action) {
  switch (action.type) {
    case PREDICTION_SOURCE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PREDICTION_SOURCE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PREDICTION_SOURCE_POST:
      return { isLoading: false, isCompleted: true };
    case PREDICTION_SOURCE_PUT:
      return { isLoading: false, isCompleted: true };
    case PREDICTION_SOURCE_DEL:
      return { isLoading: false, isCompleted: true };
    case PREDICTION_SOURCE_LOADING:
      return { isLoading: true, isCompleted: true };
    case PREDICTION_SOURCE_ERROR:
      return { isLoading: false, isCompleted: false };
    default:
      return state;
  }
}
