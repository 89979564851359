import React from 'react';
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

export function ProjectForm({
  errors,
  project,
  control,
  destinationSystem,
  showAllowCreateCheck,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-1">
        <Controller
          name="name"
          control={control}
          defaultValue={project ? project.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-1">
        <Controller
          name="api_prefix"
          control={control}
          defaultValue={project ? project.api_prefix : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="API URL ของระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.api_prefix && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        {!_.isEmpty(destinationSystem?.rows) && (
          <Controller
            name="base_system"
            control={control}
            defaultValue={project ? project.base_system : {}}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                disablePortal
                options={destinationSystem?.rows}
                size="small"
                placeholder="ระบบต้นแบบ"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                className="z-40"
                // prettier-ignore
                getOptionLabel={(option) => `${option?.name || ''}`
          }
                renderInput={(params) => (
                  <TextField label="ระบบต้นแบบ" {...params} />
                )}
              />
            )}
          />
        )}
      </div>

      <div className="w-full px-1 py-1">
        <Controller
          name="access_token"
          control={control}
          defaultValue={project ? project.access_token : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Access Token"
              fullWidth
              size={'small'}
              helperText={errors.acce && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      {showAllowCreateCheck && (
        <div className="w-full px-1 py-1">
          <Controller
            name="allow_create_all_attribute"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="สร้างตัวแปรสำหรับการทำนายผลจากที่กำหนดไว้ในระบบ"
                control={<Checkbox {...field} />}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

ProjectForm.propTypes = {
  errors: PropTypes.object,
  project: PropTypes.object,
  control: PropTypes.object,
  destinationSystem: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  showAllowCreateCheck: PropTypes.bool,
};

export default ProjectForm;
