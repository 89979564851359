import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

function DesktopDateTimePicker({
  label,
  value,
  setValue = () => {},
  onChange = () => {},
  inputFormat = 'DD MMM BBBB HH:mm',
  size = 'normal',
  fullWidth = false,
}) {
  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChange(inputValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={handleChange}
        inputFormat={inputFormat}
        renderInput={(params) => (
          <TextField size={size} {...params} fullWidth={fullWidth} />
        )}
      />
    </LocalizationProvider>
  );
}

DesktopDateTimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  inputFormat: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default DesktopDateTimePicker;
