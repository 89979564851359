import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { BackButton, ViewTitle, DestinationSystemForm } from '../../components';
import * as actions from '../../redux/actions';

function CreateDestinationSystem({ title, subtitle }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.destinationSystemCreate(data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สำเร็จ ${error?.message || ''}`);
    }
  };

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <ViewTitle title={title} subtitle={subtitle} />
        </div>
        <BackButton />
      </div>
      <div className="my-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <DestinationSystemForm control={control} errors={errors} />
            </CardContent>
          </Card>
          <div className="flex justify-end my-2">
            <Button color="primary" variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateDestinationSystem;

CreateDestinationSystem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateDestinationSystem.defaultProps = {
  title: '',
  subtitle: '',
};
