import {
  PROJ_PREDICT_ATTR_ALL,
  PROJ_PREDICT_ATTR_GET,
  PROJ_PREDICT_ATTR_DEL,
  PROJ_PREDICT_ATTR_PUT,
  PROJ_PREDICT_ATTR_POST,
  PROJ_PREDICT_ATTR_LOADING,
  PROJ_PREDICT_ATTR_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const projPredictAttrCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROJ_PREDICT_ATTR_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/project-prediction-attribute`,
      payload,
    );
    dispatch({ type: PROJ_PREDICT_ATTR_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROJ_PREDICT_ATTR_ERROR });
    throw new Error(error);
  }
};

export const projPredictAttrAll = ({
  name = '',
  size = null,
  page = 1,
  project = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/project-prediction-attribute?name=${name}&size=${size}&page=${page}&project=${project}`,
    );
    if (status === 200) {
      dispatch({ type: PROJ_PREDICT_ATTR_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROJ_PREDICT_ATTR_ERROR });
    throw new Error(error);
  }
};

export const projPredictAttrGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/project-prediction-attribute/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PROJ_PREDICT_ATTR_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROJ_PREDICT_ATTR_ERROR });
    throw new Error(error);
  }
};

export const projPredictAttrPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PROJ_PREDICT_ATTR_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/project-prediction-attribute/${id}`,
      payload,
    );
    dispatch({ type: PROJ_PREDICT_ATTR_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROJ_PREDICT_ATTR_ERROR });
    throw new Error(error);
  }
};
export const projPredictAttrDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROJ_PREDICT_ATTR_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/project-prediction-attribute/${id}`,
    );
    dispatch({ type: PROJ_PREDICT_ATTR_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PROJ_PREDICT_ATTR_ERROR });
    throw new Error(error);
  }
};
