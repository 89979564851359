import _ from 'lodash';

export const decodeAPIURL = (apiPrefix, apiSuffix, parameters = {}) => {
  const parametersKeyPadding = _.map(parameters, (value, key) => {
    const paddingKey = '{' + key + '}';
    return { paddingKey, originKey: key };
  });

  let newApiSuffix = apiSuffix;
  _.map(parametersKeyPadding, (eachKeyPadding) => {
    newApiSuffix = _.replace(
      newApiSuffix,
      eachKeyPadding.paddingKey,
      parameters?.[eachKeyPadding.originKey],
    );
  });

  const fullApi = apiPrefix + newApiSuffix;
  console.log('Full API', fullApi);
  return fullApi;
};

export default decodeAPIURL;
