import React from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Slider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { SAMPLING_RATE } from '../../utils/constants';

import DatePicker from '../DatePicker/DesktopDateTimePicker';
import ParameterInputRender from './ParameterInputRender';

const ProjectAttributeRowEditorMode = ({
  handleUpdateDatabase,
  dataRow,
  dataIndex,
  handleDisplayMode,
  control,
  watch,
}) => {
  const marks = [
    {
      value: 0.001,
    },
    {
      value: 0.01,
    },
    {
      value: 0.1,
    },
  ];
  return (
    <TableRow
      key={dataRow._id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {dataIndex}
      </TableCell>
      <TableCell>
        <div className="font-semibold">{dataRow?.prediction_source?.name}</div>
        <div className="w-32">
          API Path: <code>{dataRow?.prediction_source?.api_suffix} </code>
        </div>
        <Controller
          name="id"
          defaultValue={dataRow?._id}
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />
      </TableCell>
      <TableCell>
        <div className="w-48">
          <div className="flex flex-col">
            <div className="my-2">
              <Controller
                name="start"
                defaultValue={dataRow?.start || new Date()}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    fullWidth
                    {...field}
                    inputFormat="D/MM/YYYY HH:mm"
                    size="small"
                    label="เริ่มต้น"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <Controller
                name="end"
                defaultValue={dataRow?.end || new Date()}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    fullWidth
                    {...field}
                    inputFormat="D/MM/YYYY HH:mm"
                    size="small"
                    label="สิ้นสุด"
                  />
                )}
              />
            </div>{' '}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="w-48">
          <div className="my-2">
            <Controller
              name="sampling_rate"
              defaultValue={dataRow?.sampling_rate}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>Sampling Rate</InputLabel>
                  <Select
                    label="Sampling Rate"
                    size="small"
                    fullWidth
                    {...field}
                  >
                    {_.map(SAMPLING_RATE, (eachSamplingRate, index) => (
                      <MenuItem
                        key={index}
                        value={eachSamplingRate?.status_code}
                      >
                        {eachSamplingRate?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="my-2">
            <Controller
              name="learning_rate"
              defaultValue={dataRow?.learning_rate}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <div>Learning Rate</div>
                  <Slider
                    {...field}
                    defaultValue={0.01}
                    valueLabelDisplay="auto"
                    step={null}
                    marks={marks}
                    max={0.1}
                    min={0.001}
                  />
                </FormControl>
              )}
            />
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="w-48">
          <ParameterInputRender
            apiPath={dataRow?.prediction_source?.api_suffix}
            control={control}
            defaultValue={dataRow}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-row flex-wrap gap-1">
          <Button
            variant="contained"
            color="success"
            size={'small'}
            onClick={handleUpdateDatabase}
          >
            บันทึก
          </Button>
          <Button
            variant="contained"
            color="inherit"
            size={'small'}
            onClick={() => {
              handleDisplayMode();
            }}
          >
            ยกเลิก
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

ProjectAttributeRowEditorMode.propTypes = {
  handleUpdateDatabase: PropTypes.func,
  dataRow: PropTypes.object,
  dataIndex: PropTypes.number,
  handleDisplayMode: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
};

export default ProjectAttributeRowEditorMode;
